import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import {
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  ASSIGN_EMPLOYEES_TO_CAREER_PATH_GROUP,
  DELETE_CAREER_PATH_GROUP,
  EMPLOYEES_BY_LOCATION,
  GET_CAREER_PATH_GROUPS_BY_ORGANIZATION,
} from 'api';
import isEmpty from 'lodash/isEmpty';
import { useAlerts } from 'hooks';
import { getRoutes } from 'utils';
import { Button, IconButton, Spinner } from '../../../../shared';
import { RetentionContext } from '../../../utils';
import { ICareerPathGroupByOrganization } from './types';
import SearchableDialog from '../../../../dialogs/SearchableDialog';
import { styled } from '../../../../material-ui';
import styles from '../../../../../styles/Retention/Location/path/viewOrganizationPaths';
import EditPathGroup from './EditPathGroup';
import { Trash } from '../../../../icons';

const StyledBox = styled(Box)(styles);
const ViewOrganizationPaths = ({
  activePath,
  setActivePath,
  setAddedEmployeeToPath,
  refetchPathStatsByLocation,
  refetchEmployeePathStats,
  refetchGetPathByLocation,
}: {
  activePath?: ICareerPathGroupByOrganization;
  setActivePath: (a?: ICareerPathGroupByOrganization) => void;
  setAddedEmployeeToPath: (a: boolean) => void;
  refetchPathStatsByLocation: () => void;
  refetchEmployeePathStats: () => void;
  refetchGetPathByLocation: () => void;
}) => {
  const [assignModalIsOpen, setAssignModalIsOpen] =
    useState<boolean>(false);
  const [currentPathGroupId, setCurrentPathGroupId] = useState<
    number | null
  >(null);
  const ctx = useContext(RetentionContext);
  const { setSimpleAlert } = useAlerts();
  const navigate = useNavigate();
  const routes = getRoutes();

  const {
    data: pathDataByOrg,
    loading: pathDataIsLoading,
    refetch: refetchCareerPathGroups,
  } = useQuery(GET_CAREER_PATH_GROUPS_BY_ORGANIZATION, {
    variables: {
      organizationId: ctx.organizationId,
      brandId: ctx.brandId,
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const [
    fetchEmployeesByLocation,
    {
      data: employeesByLocationResp,
      loading: employeesByLocationLoading,
    },
  ] = useLazyQuery(EMPLOYEES_BY_LOCATION, {
    variables: {
      locationId: ctx.locationId,
      limit: 50,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    errorPolicy: 'all',
  });

  const [
    assignEmployeesToCareerPathGroup,
    { loading: assignEmployeesLoading },
  ] = useMutation(ASSIGN_EMPLOYEES_TO_CAREER_PATH_GROUP);

  const [
    deleteCareerPathGroup,
    { loading: deleteCareerPathGroupLoading },
  ] = useMutation(DELETE_CAREER_PATH_GROUP);

  useEffect(() => {
    fetchEmployeesByLocation();
  }, []);

  const handleAssignEmployees = async (rows) => {
    if (isEmpty(rows)) {
      setAssignModalIsOpen(false);
    }
    const setAlert = ({
      title,
      subtitle,
    }: {
      title: string;
      subtitle?: string;
    }) =>
      setSimpleAlert({
        isOpen: true,
        title,
        subtitle,
        onCancel: () =>
          setSimpleAlert({
            isOpen: false,
          }),
      });

    if (rows != null && !isEmpty(rows)) {
      try {
        await assignEmployeesToCareerPathGroup({
          variables: {
            careerPathGroupId: currentPathGroupId,
            employeeIds: rows.map((row) => row.employeeId),
          },
        });
        setCurrentPathGroupId(null);
        setAssignModalIsOpen(false);
        setAddedEmployeeToPath(true);
        refetchCareerPathGroups().then(() => {
          setAlert({ title: 'Employees Added to the path' });
        });
      } catch (err) {
        setAlert({
          title: 'Hold on',
          subtitle:
            'There was an error saving employees to your path, please try again',
        });
        setActivePath(null);
      }
    }
  };

  const pathData =
    pathDataByOrg?.getCareerPathGroupsByOrganization
      ?.careerPathGroups;

  if (pathDataIsLoading) {
    return <Spinner />;
  }

  const employeesByLocation =
    employeesByLocationResp?.employeesByLocation?.results;

  const viewPath = (path) => {
    navigate(
      `${routes.employer.retention_portal.location}/${ctx.retentionPages.pathmanager}`,
      { state: path },
    );
  };

  if (!pathData || isEmpty(pathData)) {
    return (
      <Box>
        <Typography variant={'h4'}>
          There are no paths for your organization
          <Typography variant={'body1'}>
            {ctx.accessOrganization
              ? 'Click the "Create New Path" button to start assigning paths to your employees!'
              : 'Contact your organization manager to add paths for your employees'}
          </Typography>
        </Typography>
      </Box>
    );
  }

  const handleDeleteCareerPathGroup =
    (assignedCount: number, careerPathGroupId: number) =>
    async () => {
      const title = 'Delete Career Path?';
      const subtitle =
        'Users that are assigned will be unassigned but retain their completed skills/abilities.';
      setSimpleAlert({
        isOpen: true,
        title,
        subtitle: assignedCount > 0 ? subtitle : '',
        onCancel: () =>
          setSimpleAlert({
            isOpen: false,
          }),
        onSuccess: async () => {
          await deleteCareerPathGroup({
            variables: { careerPathGroupId },
          }).then(async () => {
            await refetchCareerPathGroups();
            await refetchPathStatsByLocation();
            await refetchEmployeePathStats();
            await refetchGetPathByLocation();
          });
        },
      });
    };

  const OrganizationPaths = () => (
    <StyledBox>
      {pathData.map((row: ICareerPathGroupByOrganization) => (
        <Box
          className={'path-data-card'}
          key={row.careerPathGroupId}
        >
          {employeesByLocationLoading ||
            assignEmployeesLoading ||
            (deleteCareerPathGroupLoading && (
              <Box className={'pathsLoading'}>
                <Spinner />
              </Box>
            ))}
          <Box className={'path-title-container'}>
            <Box>
              <Typography
                variant={'h3'}
                className={'path-level-title'}
              >
                {row.name ||
                  `${ctx.organizationName || ''} Path`}
              </Typography>
            </Box>
            {ctx.accessOrganization && (
              <IconButton
                className={'pathTrashIcon'}
                variant={'contained'}
                color={'primary'}
                height={30}
                width={30}
                onClick={handleDeleteCareerPathGroup(
                  row.assignedUserCount,
                  row.careerPathGroupId,
                )}
              >
                <Trash />
              </IconButton>
            )}
          </Box>
          <Typography
            variant={'body1'}
            className={'path-level-job-title'}
          >
            Start: {row.startJobType}
          </Typography>
          <Typography
            variant={'body1'}
            className={'path-level-job-title'}
          >
            End: {row.endJobType}
          </Typography>
          <Typography
            variant={'body2'}
            className={'path-level-assigned-count'}
          >
            Assigned Count:{' '}
            <span
              className={
                row.assignedUserCount > 0
                  ? 'path-level-assigned-count-assigned'
                  : ''
              }
            >
              {row.assignedUserCount}
            </span>
          </Typography>
          <Box className={'path-level-action-row'}>
            <Button
              variant={'outlined'}
              color={'secondary'}
              size={'small'}
              onClick={() => {
                setAssignModalIsOpen(true);
                setCurrentPathGroupId(row.careerPathGroupId);
              }}
            >
              Assign Employees
            </Button>
            <Button
              variant={'outlined'}
              color={'secondary'}
              size={'small'}
              onClick={() => viewPath(row)}
            >
              View Path
            </Button>
          </Box>
        </Box>
      ))}
      {!employeesByLocationLoading && (
        <SearchableDialog
          dataIsLoading={employeesByLocationLoading}
          title={'Assign this path to a team member'}
          rows={employeesByLocation}
          onClose={handleAssignEmployees}
          isOpen={assignModalIsOpen}
          multiSelect
          multiSelectLimit={100}
          hasSearch={employeesByLocation.length > 10}
          hasPagination={employeesByLocation.length > 10}
          renderRow={(dialogRow) => (
            <Box>
              <img src={dialogRow.imageUrl} alt="" />
              <Typography>{dialogRow.name}</Typography>
            </Box>
          )}
        />
      )}
    </StyledBox>
  );

  return activePath ? (
    <EditPathGroup activePath={activePath} />
  ) : (
    <OrganizationPaths />
  );
};

export default ViewOrganizationPaths;
